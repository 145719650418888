@viewport {
  width: device-width ;
  zoom: 1.0 ;
}

* { touch-action: manipulation; }

body {
  background-color: #fff;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

svg {
  background-color: #fff;
}

.header {
  padding: 20px;
  text-align: center;
}

.header-name {
  font-size: 25px;
  font-weight: bold;
}

.header-artist {
  font-size: 16px;
  font-style: italic;
  text-align: right;
  padding-top: 10px;
  margin-left: 10%;
  margin-right: 10%;
}

#harness {
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  height: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#score {
  margin: auto;
  height: 100%;
  width: 100%;
}

.notes {
  margin-left: 10%;
  margin-right: 10%;
  line-height: 1.4em;
  padding-top: 1em;
}

.pages {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  text-align: center;
}

.controls {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  text-align: center;
  margin-bottom: 60px;
}

.button {
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 30px;
  text-transform: uppercase;
  font-weight: bold;
}

.button:hover {
  cursor: pointer;
}

.button:active {
  outline: none;
}

.button.right {
  float: right;
}

.button.left {
  float: left;
}

.button:disabled {
  background-color: #ccc;
}

.button:disabled:hover {
  cursor: inherit;
}





#circle1 {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
  border: 4px rgba(0, 0, 0, 0.25) solid;
  border-top: 4px black solid;
  border-bottom: 4px black solid;
  border-radius: 50%;
  -webkit-animation: spin1 1s infinite linear;
          animation: spin1 1s infinite linear;
}

@-webkit-keyframes spin1 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes spin1 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

.flex-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  height: 100%;
  }

.flex-item:nth-child(1) {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  }

.flex-item:nth-child(2) {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  position: relative;
  }

.flex-item:nth-child(3) {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  }
